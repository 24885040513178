<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('System') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
        <b-form-checkbox
          v-model="maintenance"
          switch
        >
          <b>Maintenance mode</b>
        </b-form-checkbox>
      </div>
    </b-card>
  </div>
</template>

<script>

import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import {
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'System',
  components: {
    Breadcrumbs,
    BFormCheckbox,
    BCard,
  },
  data() {
    return {
      maintenance: false,
      breadcrumbItems: [
        {
          text: 'System',
          active: true,
        },
      ],
    }
  },
}
</script>
